import React, { useRef, useState } from 'react'
import './App.css';
import Body from './components/Body';

function App() {
  return (
      <Body />
  );
}

export default App;
